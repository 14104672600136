import React, { createContext, useContext, useEffect, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { useParams } from 'react-router-dom';

import { useQueryParams } from '@clutter/hooks';
import {
  Moving__PackingEnum,
  PricingSetFragment,
  RateGroupKindEnum,
} from '@graphql/platform';
import { AgentBanner } from '@root/components/checkout/agent_banner';
import { PreviousBookingWarningModal } from '@root/components/checkout/context/previous_booking_warning_modal';
import { TEAL_USER_ID } from '@root/components/checkout/helpers/agent_booking';
import { LoadingState } from '@root/components/checkout/product_pages/loading_state';
import {
  getInitialValues,
  InitialValues,
} from '@root/components/checkout/utilities/initial_values';
import { WT_VISITOR_TOKEN, WTProvider } from '@root/initializers/wt';
import { Action, useClientDataContext } from '@shared/client_data_context';
import {
  FlowConfig,
  useFlow,
  UseFlowResult,
} from '@shared/flow/flow_container';
import { useStabilizedFunction } from '@utils/hooks';
import { useTermsWithSubjobPackagesTest } from '@utils/hooks/ab_testing';
import { useOnMount } from '@utils/hooks/mount';
import { PricingSummary } from '@utils/hooks/pricing/use_pricing_summary';
import { useStoragePricingValues } from '@utils/hooks/pricing/use_storage_pricing_values';
import { useEligibleForDisposalZIP } from '@utils/hooks/use_eligible_for_disposal_zip';
import { plausibleZIP } from '@utils/plausible_zip';
import { DeepIntersectKeys } from '@utils/typescript';

import {
  NavigationState,
  useNavigationState,
} from './context/navigation_state';
import {
  transformMovingStepTransitionData,
  transformStorageStepTransitionData,
} from './helpers/transform_step_transition_data';
import {
  MovingQuoteCreator,
  useBoundCreateMovingQuote,
} from './helpers/use_bound_create_moving_quote';
import { Moving } from './product_pages/steps/arrays/moving';
import { SmartStorage } from './product_pages/steps/arrays/smart_storage';
import { parseCheckoutQueryString } from './utilities/checkout_url';
import {
  persistMovingSessionCheckoutData,
  persistStorageSessionCheckoutData,
} from './utilities/persistence';
import {
  MovingCheckoutData,
  SharedCheckoutData,
  StorageCheckoutData,
} from './data';
import {
  CheckoutType,
  CheckoutVersion,
  MovingCheckoutStep,
  MovingCheckoutStepProps,
  MovingStepDescriptor,
  SharedCheckoutStepProps,
  StorageCheckoutStep,
  StorageCheckoutStepProps,
  StorageStepDescriptor,
} from './types';

type StorageFlowState = UseFlowResult<
  StorageCheckoutData,
  StorageCheckoutStepProps
>;

type MovingFlowState = UseFlowResult<
  MovingCheckoutData,
  MovingCheckoutStepProps
>;

type StorageContextValue = {
  flowVersion: string;
  flowState: StorageFlowState;
  resolvedSteps: StorageStepDescriptor[];
  navigationState: NavigationState<StorageCheckoutStep>;
  pricingSet?: PricingSetFragment;
  pricingSummary?: PricingSummary;
  /** The pricing summary for a 5x5 */
  defaultPricingSummary?: PricingSummary;
  storageBundleEligible: boolean;
  disposalEligible: boolean;
  subjobPackageEligible?: boolean;
};

type MovingContextValue = {
  flowVersion: string;
  flowState: MovingFlowState;
  resolvedSteps: MovingStepDescriptor[];
  navigationState: NavigationState<MovingCheckoutStep>;
  createQuote: MovingQuoteCreator;
  creatingQuote: boolean;
  pricingSet?: PricingSetFragment;
  pricingSummary?: PricingSummary;
  /** The pricing summary for a 5x5 */
  defaultPricingSummary?: PricingSummary;
  disposalEligible: boolean;
  subjobPackageEligible?: boolean;
};

const StorageCheckoutContext = createContext<StorageContextValue | undefined>(
  undefined,
);

const MovingCheckoutContext = createContext<MovingContextValue | undefined>(
  undefined,
);

export const useStorageCheckoutContext = () => {
  const value = useContext(StorageCheckoutContext);
  if (!value) throw new Error('CheckoutContextProvider is missing!');
  return value;
};

export const useMovingCheckoutContext = () => {
  const value = useContext(MovingCheckoutContext);
  if (!value) throw new Error('CheckoutContextProvider is missing!');
  return value;
};

export const useSharedCheckoutContext = () => {
  const storageValue = useContext(StorageCheckoutContext);
  const movingValue = useContext(MovingCheckoutContext);
  if (!storageValue && !movingValue)
    throw new Error('CheckoutContextProvider is missing!');
  return (storageValue || movingValue) as unknown as DeepIntersectKeys<
    StorageContextValue,
    MovingContextValue
  >;
};

export const useSharedFlowStateContext = () => {
  const storageValue = useContext(StorageCheckoutContext);
  const movingValue = useContext(MovingCheckoutContext);
  if (!storageValue && !movingValue)
    throw new Error('CheckoutContextProvider is missing!');
  return (storageValue?.flowState || movingValue?.flowState) as UseFlowResult<
    SharedCheckoutData,
    SharedCheckoutStepProps
  >;
};

const useFlowWithSharedConfig = <Data, Props>(
  // prettier-ignore
  {
    stepTransitionConfig,
    parsedQuery: { referrer },
    ...config
  }:
    Pick<FlowConfig<Data, Props>, 'initialValues' | 'steps'>
    & {
      stepTransitionConfig: Pick<
        FlowConfig<Data, Props>['stepTransitionConfig'],
        'flowVersion' | 'transform' | 'metadata'
      >;
    } & {
      parsedQuery: ReturnType<typeof parseCheckoutQueryString>
    },
) => {
  return useFlow<Data, Props>({
    wtConfig: false,
    stepTransitionConfig: {
      resourceToken: WT_VISITOR_TOKEN,
      resourceType: 'Visitor',
      flowName: 'inline_checkout',
      ...stepTransitionConfig,
      metadata: {
        ref: referrer,
        ...stepTransitionConfig?.metadata,
      },
    },
    ...config,
  } as FlowConfig<Data, Props>);
};

const StorageProviderInner: React.FC<{
  children: React.ReactNode;
  initialValues: StorageCheckoutData;
}> = ({ initialValues, children }) => {
  const client = useApolloClient();
  const [parsedQuery] = useState(() =>
    parseCheckoutQueryString(window.location.search),
  );
  const [resolvedSteps] = useState(() =>
    parsedQuery.zipValidated
      ? SmartStorage.filter((s) => s.name !== StorageCheckoutStep.Zip)
      : SmartStorage,
  );
  const flowState = useFlowWithSharedConfig<
    StorageCheckoutData,
    StorageCheckoutStepProps
  >({
    stepTransitionConfig: {
      flowVersion: CheckoutVersion.Storage,
      transform: transformStorageStepTransitionData,
      metadata: {
        checkout_type: initialValues.checkoutType,
      },
    },
    steps: resolvedSteps,
    initialValues,
    parsedQuery,
  });

  const navigationState = useNavigationState<StorageCheckoutStep>();

  const {
    values: { zip, commitment, planSize },
  } = flowState;

  const {
    dispatch,
    data: {
      lead: { token },
    },
  } = useClientDataContext();

  const disposalEligible = useEligibleForDisposalZIP(zip);
  const subjobPackageEligible = useTermsWithSubjobPackagesTest(zip);

  const { pricingSet, pricingSummary, defaultPricingSummary } =
    useStoragePricingValues(zip, commitment, planSize);

  useEffect(() => {
    persistStorageSessionCheckoutData(
      client,
      flowState.values,
      token,
      pricingSet,
    );
  }, [client, flowState.values, pricingSet, token]);

  useEffect(() => {
    if (zip && plausibleZIP(zip)) {
      dispatch({ type: Action.SetZip, payload: { zip } });
    }
  }, [dispatch, zip]);

  return (
    <StorageCheckoutContext.Provider
      value={{
        flowVersion: CheckoutVersion.Storage,
        flowState,
        navigationState,
        resolvedSteps,
        pricingSet,
        pricingSummary,
        defaultPricingSummary,
        storageBundleEligible:
          flowState.values.commitment !== RateGroupKindEnum.Mover,
        subjobPackageEligible,
        disposalEligible,
      }}
    >
      <WTProvider
        params={{
          metadata: { flow_instance_uuid: flowState.flowInstanceUuid },
        }}
      >
        {children}
      </WTProvider>
    </StorageCheckoutContext.Provider>
  );
};

const MovingProviderInner: React.FC<{
  initialValues: MovingCheckoutData;
  children: React.ReactNode;
}> = ({ initialValues, children }) => {
  const client = useApolloClient();
  const [parsedQuery] = useState(() =>
    parseCheckoutQueryString(window.location.search),
  );
  const [resolvedSteps] = useState(() =>
    parsedQuery.zipValidated
      ? Moving.filter((s) => s.name !== MovingCheckoutStep.Zip)
      : Moving,
  );
  const flowState = useFlowWithSharedConfig<
    MovingCheckoutData,
    MovingCheckoutStepProps
  >({
    stepTransitionConfig: {
      flowVersion: CheckoutVersion.Moving,
      transform: transformMovingStepTransitionData,
      metadata: {
        checkout_type: initialValues.checkoutType,
      },
    },
    steps: resolvedSteps,
    initialValues: {
      ...initialValues,
      skipAddressEligible: parsedQuery.skipAddressEligible,
    },
    parsedQuery,
  });
  const [quoteLoading, setQuoteLoading] = useState(false);

  const navigationState = useNavigationState<MovingCheckoutStep>();

  const {
    dispatch,
    data: {
      lead: { token },
    },
  } = useClientDataContext();

  const createQuote = useBoundCreateMovingQuote(flowState.values);
  const createQuoteAndUpdate = useStabilizedFunction(async () => {
    setQuoteLoading(true);

    try {
      const result = await createQuote();
      const movingQuote = result?.data?.movingQuoteCreate?.quote ?? undefined;
      flowState.onChange('movingQuote', movingQuote);

      return result;
    } finally {
      setQuoteLoading(false);
    }
  });

  const {
    values: { zip, endAddress, commitment, planSize },
    onChange,
  } = flowState;

  const { pricingSet, pricingSummary, defaultPricingSummary } =
    useStoragePricingValues(endAddress?.zip || zip, commitment, planSize);

  const disposalEligible = useEligibleForDisposalZIP(zip);
  const subjobPackageEligible = useTermsWithSubjobPackagesTest(zip);

  useEffect(() => {
    persistMovingSessionCheckoutData(
      client,
      flowState.values,
      token,
      pricingSet,
    );
  }, [client, flowState.values, token]);

  useEffect(() => {
    const zip = flowState.values.zip;
    if (zip && plausibleZIP(zip)) {
      dispatch({ type: Action.SetZip, payload: { zip } });
    }
  }, [dispatch, flowState.values.zip]);

  useEffect(() => {
    onChange('packingHelp', Moving__PackingEnum.AllItems);
  }, [onChange]);

  return (
    <MovingCheckoutContext.Provider
      value={{
        flowVersion: CheckoutVersion.Moving,
        flowState,
        navigationState,
        resolvedSteps,
        createQuote: createQuoteAndUpdate,
        creatingQuote: quoteLoading,
        pricingSet,
        pricingSummary,
        defaultPricingSummary,
        disposalEligible,
        subjobPackageEligible,
      }}
    >
      <WTProvider
        params={{
          metadata: { flow_instance_uuid: flowState.flowInstanceUuid },
        }}
      >
        {children}
      </WTProvider>
    </MovingCheckoutContext.Provider>
  );
};

export const CheckoutContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const client = useApolloClient();
  const {
    dispatch,
    data: { draft },
  } = useClientDataContext();
  const { service } = useParams<{ service: 'storage' | 'moving' }>();
  const [initialValues, setInitialValues] = useState<InitialValues>();
  const { lead_token: leadToken, customer_token: customerToken } =
    useQueryParams();
  const initialValueOptions = (() => {
    if (leadToken) {
      return {
        type: CheckoutType.Shareable,
        leadToken,
        client,
      } as const;
    }
    if (customerToken) {
      return {
        type: CheckoutType.Reonboarding,
        customerToken,
        client,
      } as const;
    }
    return { type: CheckoutType.Standard, draft } as const;
  })();

  useOnMount(async () => {
    const { storageData, movingData } = await getInitialValues(
      client,
      initialValueOptions,
    );
    const { leadToken: storedLeadToken, email } =
      service === 'moving' ? movingData : storageData;
    if (storedLeadToken && email) {
      dispatch({
        type: Action.SetLead,
        payload: { token: storedLeadToken, email },
      });
    }

    setInitialValues({ storageData, movingData });
  });

  if (!initialValues)
    return (
      <LoadingState checkoutType={initialValueOptions.type} service={service} />
    );

  return (
    <>
      {TEAL_USER_ID && <AgentBanner />}
      {service === 'moving' ? (
        <MovingProviderInner initialValues={initialValues.movingData}>
          {children}
        </MovingProviderInner>
      ) : (
        <StorageProviderInner initialValues={initialValues.storageData}>
          {children}
        </StorageProviderInner>
      )}
      <PreviousBookingWarningModal />
    </>
  );
};
