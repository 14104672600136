import React, { useState } from 'react';

import styled from '@emotion/styled';

import { Box, COLORS, FontWeight, mq, Text, TextButton } from '@clutter/clean';
import { RateGroupKindEnum } from '@graphql/platform';
import { useSharedCheckoutContext } from '@root/components/checkout/context';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';
import { Commitment as CommitmentType } from '@root/resources/types/commitment';
import { InfoTooltip } from '@shared/info_tooltip';
import { formatCurrency, WHOLE_NUMBER_OPTIONS } from '@utils/formatters';
import { useStoragePriceCompletedFunnelEvent } from '@utils/hooks/funnel_events/use_storage_price_completed_funnel_event';
import {
  PricingSummary,
  roundedDiscountPercentage,
} from '@utils/hooks/pricing/use_pricing_summary';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';
import { useFreePickupReturnEligible } from '@utils/hooks/use_free_pickup_return_eligible';
import { useLastDefined } from '@utils/hooks/use_last_defined';
import { extractStoragePrice } from '@utils/pricing/pricing_set';

import { FeaturesOverlay } from '../../subcomponents/features_overlay';
import { SMART_STORAGE_COMMITMENT_FEATURES } from '../../subcomponents/features_overlay/data';
import { RadioCard } from '../../subcomponents/radio_card';
import { StoragePlanValueProps } from '../../subcomponents/storage_plan_value_props';

import { Superscript } from './bundles/superscript';
import { DiscountBanner } from './commitment/discount_banner';
import { SavingsSummary } from './commitment/savings_summary';

const StyledRadioCard = styled(RadioCard)`
  & > div {
    height: 100%;
  }
`;

const CommitmentCards = styled.div`
  display: grid;
  ${mq({
    gridTemplateColumns: ['1fr', null, '1fr 1fr 1fr'],
    gridGap: ['10px', null, '16px'],
  })}
`;

const CommitmentCardContents: React.FC<{
  commitment: RateGroupKindEnum;
  summary?: PricingSummary;
  defaultPercent?: number;
  titleText: React.ReactNode;
  description?: React.ReactNode;
}> = ({ commitment, summary, defaultPercent, titleText, description }) => {
  const discountedPrice =
    commitment === RateGroupKindEnum.Saver
      ? summary?.saverMonthlyAmount
      : commitment === RateGroupKindEnum.Flexer
      ? summary?.flexerMonthlyAmount
      : undefined;

  const discountText =
    commitment === RateGroupKindEnum.Mover
      ? 'Most flexible'
      : `${
          summary && discountedPrice
            ? roundedDiscountPercentage(
                summary.moverMonthlyAmount,
                discountedPrice,
              )
            : defaultPercent
        }% discount`;

  return (
    <>
      {!description && (
        <Box.Flex
          textAlign="center"
          flexDirection="column"
          justifyContent="center"
          height="100%"
          padding="0 4px"
        >
          <Text.Title size="extraSmall">{titleText}</Text.Title>
          <Box
            padding="2px 4px"
            margin="0 auto"
            background={
              commitment === RateGroupKindEnum.Mover
                ? 'transparent'
                : COLORS.dust
            }
            borderRadius="4px"
          >
            <Text.Caption weight={FontWeight.Medium} color={COLORS.moss}>
              {discountText}
            </Text.Caption>
          </Box>
        </Box.Flex>
      )}
      {description && (
        <Box.Flex
          textAlign="center"
          flexDirection="column"
          justifyContent={['space-evenly', undefined]}
          height="100%"
          padding="0 4px"
        >
          <Box.FlexItem margin="0 0 8px">
            <Text.Title size="extraSmall">{titleText}</Text.Title>
          </Box.FlexItem>
          <Box textAlign={['center', null, null, 'left']}>
            <Text.Callout color={COLORS.storm}>{description}</Text.Callout>
          </Box>
        </Box.Flex>
      )}
    </>
  );
};

type CommitmentStepProps = StorageCheckoutStepProps & {
  movingCheckoutFlow: boolean;
};

export const Commitment: React.FC<CommitmentStepProps> = ({
  onChange,
  scrollToStep,
  values: { commitment, planSize },
  movingCheckoutFlow,
}) => {
  const [showOverlay, setShowOverlay] = useState<boolean>(false);
  const { isMobile } = useBreakpoints();
  const {
    pricingSummary: summary,
    defaultPricingSummary,
    subjobPackageEligible,
    pricingSet,
  } = useSharedCheckoutContext();
  const track = useTrack({ action: 'click' });

  const freePickupReturnEligible = useFreePickupReturnEligible();
  const pricingSummary = useLastDefined(summary);
  const trackStoragePriceCompleted = useStoragePriceCompletedFunnelEvent();
  const visible = !movingCheckoutFlow || (movingCheckoutFlow && planSize);

  const handleChange = (term: CommitmentType) => {
    if (planSize && pricingSet) {
      // This can't be read from the pricing summary as fees are currently
      // loaded asynchronously.
      const amount = extractStoragePrice(term, planSize, pricingSet);
      trackStoragePriceCompleted({
        planSize,
        commitment: term,
        amount: amount!,
      });
    }

    onChange('commitment', term);
    !isMobile && scrollToStep();
  };

  const handleLearnMoreClick = () => {
    track({
      objectName: 'features',
      objectType: 'button',
      label: 'Learn more about moving',
    });
    setShowOverlay(true);
  };

  if (!visible) {
    return null;
  }

  const flexerDefaultPercent = defaultPricingSummary
    ? roundedDiscountPercentage(
        defaultPricingSummary.moverMonthlyAmount,
        defaultPricingSummary.flexerMonthlyAmount,
      )
    : 20;

  const saverDefaultPercent = defaultPricingSummary
    ? roundedDiscountPercentage(
        defaultPricingSummary.moverMonthlyAmount,
        defaultPricingSummary.saverMonthlyAmount,
      )
    : 40;

  const moverCard = (
    <StyledRadioCard
      selected={commitment === RateGroupKindEnum.Mover}
      onChange={() => handleChange(RateGroupKindEnum.Mover)}
      name="commitment"
      value="mover"
    >
      <CommitmentCardContents
        commitment={RateGroupKindEnum.Mover}
        summary={pricingSummary}
        titleText="Month to month"
        description={
          subjobPackageEligible ? (
            <>Additional pickups and deliveries can be purchased.</>
          ) : undefined
        }
      />
    </StyledRadioCard>
  );

  const flexerCard = (
    <StyledRadioCard
      selected={commitment === RateGroupKindEnum.Flexer}
      onChange={() => handleChange(RateGroupKindEnum.Flexer)}
      name="commitment"
      value="flexer"
    >
      <CommitmentCardContents
        commitment={RateGroupKindEnum.Flexer}
        summary={pricingSummary}
        defaultPercent={flexerDefaultPercent}
        titleText={
          <>
            4 <span style={{ whiteSpace: 'nowrap' }}>months +</span>
          </>
        }
        description={
          subjobPackageEligible ? (
            <>
              Get <b>one</b> free additional pickup or delivery.
            </>
          ) : undefined
        }
      />
    </StyledRadioCard>
  );

  const saverCard = (
    <StyledRadioCard
      selected={commitment === RateGroupKindEnum.Saver}
      onChange={() => handleChange(RateGroupKindEnum.Saver)}
      name="commitment"
      value="saver"
    >
      <CommitmentCardContents
        commitment={RateGroupKindEnum.Saver}
        summary={pricingSummary}
        defaultPercent={saverDefaultPercent}
        titleText={
          <>
            8 <span style={{ whiteSpace: 'nowrap' }}>months +</span>
          </>
        }
        description={
          subjobPackageEligible ? (
            <>
              Get <b>four</b> free additional pickups or deliveries.
            </>
          ) : undefined
        }
      />
    </StyledRadioCard>
  );

  return (
    <>
      <Box margin="48px 0 0">
        <Box.Flex justifyContent="space-between">
          <Text.Title size="small" color={COLORS.tealDark}>
            {subjobPackageEligible
              ? 'How long would you like to store?'
              : 'Select term length'}
          </Text.Title>
          {subjobPackageEligible && (
            <Box.FlexItem
              flexShrink={0}
              display={['none', null, null, 'initial']}
              alignSelf="center"
            >
              <TextButton onClick={handleLearnMoreClick}>Learn more</TextButton>
            </Box.FlexItem>
          )}
        </Box.Flex>
        <Box margin="4px 0 0">
          <Text.Body color={COLORS.storm}>
            {subjobPackageEligible ? (
              <>
                Your first pickup to move items into storage and final delivery
                to move items out of storage is included for free
                <Superscript>1</Superscript> with every storage plan. We’ll
                provide additional appointments free of charge with a 4 or 8
                month minimum storage commitment.
              </>
            ) : (
              'The longer you store, the more you save.'
            )}
          </Text.Body>
        </Box>
        <Box>
          <Box margin="16px 0 0" position="relative">
            {freePickupReturnEligible && !subjobPackageEligible ? (
              <>
                <Box.Grid
                  gridTemplateColumns={['1fr', null, '1fr 1fr']}
                  gridGap={['10px', null, '16px']}
                >
                  {moverCard}
                  {flexerCard}
                </Box.Grid>
                <DiscountBanner>
                  <Text.Title size="extraSmall" color={COLORS.cloud}>
                    Free pickup
                  </Text.Title>
                  <Text.Body color={COLORS.cloud}>
                    Free first pickup when you store for at least 4 months!
                    You’ll never visit a self-storage unit again. It’s that
                    simple.
                  </Text.Body>
                </DiscountBanner>
              </>
            ) : (
              <CommitmentCards>
                {moverCard}
                {flexerCard}
                {saverCard}
              </CommitmentCards>
            )}
          </Box>
          {subjobPackageEligible &&
            (defaultPricingSummary?.freeDuration ||
              pricingSummary?.freeDuration) && (
              <Box margin="16px 0 0">
                <Text.Caption color={COLORS.hippo}>
                  <Superscript>1</Superscript> Free up to{' '}
                  {defaultPricingSummary?.freeDuration ||
                    pricingSummary?.freeDuration}{' '}
                  hours and then charges apply.{' '}
                  {pricingSummary?.fullServiceLaborRateAmount && (
                    <InfoTooltip
                      contents={`Billed hourly at ${formatCurrency(
                        pricingSummary?.fullServiceLaborRateAmount,
                        WHOLE_NUMBER_OPTIONS,
                      )}/hour thereafter.`}
                    />
                  )}
                </Text.Caption>
              </Box>
            )}
          {!subjobPackageEligible && (
            <Box margin="24px 0 0">
              <SavingsSummary />
            </Box>
          )}
        </Box>
        <Box margin="32px 0 0" display={['block', null, 'none']}>
          <StoragePlanValueProps />
        </Box>
      </Box>
      {showOverlay && (
        <FeaturesOverlay
          isOpen={showOverlay}
          onClose={() => setShowOverlay(false)}
          content={SMART_STORAGE_COMMITMENT_FEATURES}
        />
      )}
    </>
  );
};
