import { booleanResultResolver, useAbeetResult } from '@clutter/abeet';
import { useGeocodeQuery } from '@graphql/platform';
import { useAbeetVisitorTest } from '@shared/abeet';

export const PRICING_TEST_INELIGIBLE_REGIONS = ['Portland', 'Seattle'];

export function useCouponDefaultTest() {
  const result = useAbeetResult('web_visitor_coupon_default');
  return result
    ? {
        promoCode: result.promo_code,
        expirationDate: result.expiration_date,
      }
    : {};
}

export function useTermsWithSubjobPackagesTest(zip?: string) {
  const { data } = useGeocodeQuery({
    variables: { zip: zip ?? '' },
    skip: !zip,
  });
  const regionName = data?.geocode?.region?.name;
  const isIneligiblePricingTestRegion =
    PRICING_TEST_INELIGIBLE_REGIONS.includes(regionName ?? '');
  const result = useAbeetVisitorTest('web_visitor_labor', {
    resolver: booleanResultResolver('subjob_packages'),
    skip: !regionName || isIneligiblePricingTestRegion,
  })[0];
  return result || false;
}
