import React, { useRef, useState } from 'react';

import styled from '@emotion/styled';

import { Box, Button, COLORS, Text } from '@clutter/clean';
import {
  CallbackDepartment,
  CallbackWindow,
  CustomerTicket__ContactPreference,
  ErrorCodeEnum,
  useZendeskCallbackRequestCreateMutation,
} from '@graphql/platform';
import { useTrack, WT_VISITOR_TOKEN } from '@root/initializers/wt';
import { Errors } from '@root/resources/errors';
import { PHONE_MASK } from '@root/resources/masks';
import { IMapping } from '@root/resources/types/mapping';
import { ServiceEnum } from '@root/resources/types/service';
import { LeadForm, LeadFormRef } from '@shared/lead_form';
import {
  ErrorWithoutTypename,
  FormattedGQLError,
  GQLError,
} from '@utils/gql_errors';
import { titleize } from '@utils/text';

import { CallbackWindows } from './callback_windows';

const LeadFormContainer = styled.div`
  border-top: 1px solid ${COLORS.grayBorder};
  padding-top: 12px;
  text-align: left;
`;

const ErrorContainer: React.FC<{
  margin?: string;
  children: React.ReactNode;
}> = ({ margin, children }) => (
  <Box
    background={COLORS.flower}
    padding="12px"
    borderRadius="4px"
    margin={margin || 'initial'}
  >
    <Text.Callout>{children}</Text.Callout>
  </Box>
);

const MAPPING: IMapping = {
  phone: {
    type: 'text',
    label: 'Phone number',
    mask: PHONE_MASK,
    required: true,
    inputProps: {
      type: 'tel',
      autoComplete: 'phone',
    },
  },
  name: {
    type: 'text',
    label: 'Full name',
    transformation: titleize,
    required: true,
    inputProps: {
      autoComplete: 'name',
    },
  },
};

const SUBMIT_BUTTON_LABEL = 'Confirm';

const DEFAULT_SERVICE = ServiceEnum.DoorToDoor;

type LeadAttributes = {
  name: string;
  phone: string;
  email?: string;
  zip?: string;
};

export const Form: React.FC<{
  department?: CallbackDepartment;
  email?: string;
  zip?: string;
  service?: ServiceEnum;
  tags?: string[];
  onLeadCreated?: (leadAttributes: LeadAttributes) => void;
  onConfirm: (_: CallbackWindow) => void;
}> = ({
  department,
  email,
  zip,
  service = DEFAULT_SERVICE,
  tags,
  onLeadCreated,
  onConfirm,
}) => {
  const [selectedCallbackWindow, setSelectedCallbackWindow] =
    useState<CallbackWindow>();
  const [leadAttributes, setLeadAttributes] = useState<LeadAttributes>({
    name: '',
    phone: '',
    email,
    zip,
  });
  const ref = useRef<LeadFormRef>(null);
  const [{ errors }, setErrors] = useState(() => ({ errors: new Errors() }));
  const [gqlError, setGqlError] = useState<ErrorWithoutTypename>();
  const [missingWindowError, setMissingWindowError] = useState<
    string | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const track = useTrack();
  const [createCallback] = useZendeskCallbackRequestCreateMutation();

  const onWindowSelect = (window: CallbackWindow) => {
    setMissingWindowError(undefined);
    setSelectedCallbackWindow(window);
  };

  const handleChange = (field: 'name' | 'phone', value: string) => {
    errors.reset(field);
    setGqlError(undefined);
    setLeadAttributes({ ...leadAttributes, [field]: value });
  };

  const validateName = () => {
    const { name } = leadAttributes;
    if (!name || name.trim().split(' ').length < 2) {
      errors.add('name', 'Please provide your full name.');
    }
  };

  const validatePhone = () => {
    const { phone } = leadAttributes;
    if (!phone) {
      errors.add('phone', 'Phone is required.');
    }
  };

  const perform = async () => {
    const { id: leadID } = await ref.current!.createLead();
    if (leadID) {
      onLeadCreated?.(leadAttributes);
      const { data } = await createCallback({
        variables: {
          leadID,
          callbackAt: selectedCallbackWindow!.time,
          contactPreference: CustomerTicket__ContactPreference.Phone,
          tags,
          visitorToken: WT_VISITOR_TOKEN,
        },
      });
      if (data?.result?.error) {
        throw new GQLError(data.result.error);
      }
      onConfirm(selectedCallbackWindow!);
      track({
        action: 'display',
        objectName: 'callback_create_success_message',
        metadata: {
          callback_time: selectedCallbackWindow?.time,
          duration: selectedCallbackWindow?.duration,
          ...leadAttributes,
        },
      });
    }
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();

    if (loading) return;

    track({
      action: 'click',
      objectName: 'callback_submit',
      objectType: 'button',
      label: SUBMIT_BUTTON_LABEL,
      metadata: {
        callback_time: selectedCallbackWindow?.time,
        duration: selectedCallbackWindow?.duration,
        ...leadAttributes,
      },
    });

    setMissingWindowError(undefined);
    errors.reset();

    validateName();
    validatePhone();

    if (!selectedCallbackWindow) {
      setMissingWindowError('Please select a time for us to call you.');
      return;
    }

    if (errors.any) {
      setErrors({ errors });
    }

    try {
      setLoading(true);
      await perform();
    } catch (error) {
      if (error instanceof GQLError) {
        if (error.fullError.errorCode === ErrorCodeEnum.InvalidPhone) {
          errors.add('phone', 'Please enter a valid phone number.');
        } else if (
          error.fullError.errorCode === ErrorCodeEnum.ZendeskApiError
        ) {
          setGqlError(error.fullError);
        }
      }
      setErrors({ errors });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <CallbackWindows
        department={department}
        selectedWindow={selectedCallbackWindow}
        onSelect={onWindowSelect}
      />
      {missingWindowError && (
        <ErrorContainer margin="12px 0">{missingWindowError}</ErrorContainer>
      )}
      <LeadFormContainer>
        <LeadForm
          ref={ref}
          errors={errors}
          fields={['name', 'phone']}
          disabled={loading}
          leadAttributes={{ ...leadAttributes }}
          validateCustomerStatus={false}
          serviceContext={service}
          onChange={handleChange}
          mapping={MAPPING}
        />
      </LeadFormContainer>
      {gqlError && (
        <ErrorContainer margin="0 0 24px">
          <FormattedGQLError error={gqlError} />
        </ErrorContainer>
      )}
      <Button fullWidth type="submit" disabled={loading} loading={loading}>
        {SUBMIT_BUTTON_LABEL}
      </Button>
    </form>
  );
};
