import React, { useEffect, useState } from 'react';

import { Box, COLORS, FontWeight, Text, UnstyledButton } from '@clutter/clean';
import { useGeocodeQuery } from '@graphql/platform';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { ServiceEnum } from '@root/resources/types/service';
import { useLeadCaptureViewedFunnelEvent } from '@utils/hooks/funnel_events/use_lead_capture_viewed_funnel_event';
import { useBreakpoints } from '@utils/hooks/use_breakpoints';

import { Banner as CouponBanner } from '../../subcomponents/coupon/banner';
import { LeadFields } from '../shared/lead_fields';

import { AddressFields } from './address_and_lead_info/address_fields';

import locationPin from '@images/illustrations/location_truck_tracking.svg';

export const AddressAndLeadInfo: React.FC<StorageCheckoutStepProps> = (
  props,
) => {
  const [zipChanged, setZipChanged] = useState(false);
  const [showZipChangeMessage, setShowZipChangeMessage] = useState(false);
  const { isDesktop } = useBreakpoints();

  const {
    scrollToStep,
    enabled,
    values: { zip, address },
  } = props;

  const { data, loading: loadingGeocode } = useGeocodeQuery({
    variables: { zip: zip! },
    skip: !zip,
  });
  const storageEligible = !!data?.geocode?.eligibleForDoorToDoor;

  useLeadCaptureViewedFunnelEvent(enabled);

  const handleLeadCreate = (wasCreated: boolean) => {
    if (zipChanged) {
      setShowZipChangeMessage(true);
    }

    if (wasCreated && storageEligible && !zipChanged) {
      scrollToStep('next', isDesktop ? 'centered' : 'top');
    }
  };

  useEffect(() => {
    setShowZipChangeMessage(false);
  }, [props.values.zip]);

  return (
    <Box margin={['48px 0 0', null, '72px 0 0']}>
      <Box>
        <Text.Title size="small" color={COLORS.tealDark}>
          Check availability
        </Text.Title>
      </Box>
      <Box margin="4px 0 32px">
        <Text.Body color={COLORS.storm}>
          Enter the address where we will pick up your items. We’ll also send
          your rates via email.
        </Text.Body>
        <Box margin="16px 0 0">
          <CouponBanner />
        </Box>
      </Box>
      <AddressFields {...props} onZipChanged={setZipChanged} />
      <Box>
        <LeadFields
          {...props}
          onLeadCreate={handleLeadCreate}
          serviceContext={ServiceEnum.DoorToDoor}
          loading={loadingGeocode}
          disabled={(!loadingGeocode && !storageEligible) || !address?.street}
        />
      </Box>
      {!loadingGeocode && (showZipChangeMessage || !storageEligible) && (
        <Box.Flex
          alignItems="flex-start"
          background={COLORS.grayBorder}
          borderRadius="4px"
          margin="16px 0 0"
          padding="24px"
        >
          <img src={locationPin} width="45" alt="" />
          <Box margin="0 0 0 16px">
            {storageEligible ? (
              <Text.Callout>
                <b>Your ZIP code has been updated!</b>
                <Box margin="4px 0 12px">
                  Storage pricing has changed. Review your updated monthly
                  price.
                </Box>
                <UnstyledButton onClick={() => window.scrollTo(0, 0)}>
                  <Text.Callout
                    color={COLORS.tealPrimary}
                    weight={FontWeight.Medium}
                  >
                    Review storage pricing
                  </Text.Callout>
                </UnstyledButton>
              </Text.Callout>
            ) : (
              <Text.Callout>
                <b>We don’t currently service this area!</b>
                <Box margin="4px 0 0">
                  Clutter is expanding every day, check back soon!
                </Box>
              </Text.Callout>
            )}
          </Box>
        </Box.Flex>
      )}
    </Box>
  );
};
