import React from 'react';

import { Box, COLORS, FontWeight, Text } from '@clutter/clean';
import {
  DoorToDoor__BundleEnum,
  LaborBillingFormatEnum,
} from '@graphql/platform';
import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { CardContents } from '@root/components/checkout/product_pages/subcomponents/card_contents';
import { PerMoverHourDisclaimer } from '@root/components/checkout/product_pages/subcomponents/onboarding_fees/per_mover_hour_disclaimer';
import { RadioCard } from '@root/components/checkout/product_pages/subcomponents/radio_card';

import moverWithBoxes from '@images/illustrations/mover_with_boxes_small.svg';

export const ChallengerBundleCards: React.FC<{
  selectedBundleKind?: DoorToDoor__BundleEnum;
  onBundleKindSelect(bundle: DoorToDoor__BundleEnum): void;
}> = ({ selectedBundleKind, onBundleKindSelect }) => {
  const { pricingSummary } = useStorageCheckoutContext();
  const { fullServiceLaborRateAmount, bundles } = pricingSummary || {};

  const basicBundle = bundles?.find(
    (b) => b.kind === DoorToDoor__BundleEnum.Basic,
  );
  const pickupAndPackingBundle = bundles?.find(
    (b) => b.kind === DoorToDoor__BundleEnum.PickupAndPacking,
  );

  if (!basicBundle || !pickupAndPackingBundle || !fullServiceLaborRateAmount)
    return null;

  const perMoverHour =
    pricingSummary?.laborBillingFormat === LaborBillingFormatEnum.PerMoverHour;

  return (
    <Box margin={['48px 0 0', null, '72px 0 0']}>
      <Text.Headline weight={FontWeight.Medium} color={COLORS.tealDark}>
        Do you need packing help?
      </Text.Headline>

      <Box.Grid
        margin={['24px 0 0', '32px 0 0']}
        gridTemplateColumns={['1fr', '1fr 1fr']}
        gridGap={['12px', '16px']}
      >
        <RadioCard
          name="protectionPlan"
          value="Premium"
          selected={
            selectedBundleKind === DoorToDoor__BundleEnum.PickupAndPacking
          }
          onChange={() =>
            onBundleKindSelect(DoorToDoor__BundleEnum.PickupAndPacking)
          }
        >
          <CardContents
            label="Yes, help me pack"
            description="Our team will pack your boxes and disassemble your furniture. Unlimited boxes and packing materials included!"
            price={pickupAndPackingBundle.packageSetEntry?.amount ?? undefined}
            imgSrc={moverWithBoxes}
          />
        </RadioCard>
        <RadioCard
          name="protectionPlan"
          value="Standard"
          selected={selectedBundleKind === DoorToDoor__BundleEnum.Basic}
          onChange={() => onBundleKindSelect(DoorToDoor__BundleEnum.Basic)}
        >
          <CardContents
            label="No, I’ll do it myself"
            description="You’ll pack your own boxes and disassemble furniture. Clutter will still provide materials to wrap furniture items."
            showLoader={false}
          />
        </RadioCard>
      </Box.Grid>
      {perMoverHour && (
        <Box margin={['12px 0 0', '16px 0 0']}>
          <PerMoverHourDisclaimer pricingSummary={pricingSummary} />
        </Box>
      )}
    </Box>
  );
};
