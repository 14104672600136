import React, { useEffect } from 'react';

import { Box } from '@clutter/clean';
import { DoorToDoor__BundleEnum, RateGroupKindEnum } from '@graphql/platform';
import { useStorageCheckoutContext } from '@root/components/checkout/context';
import { OnboardingFees } from '@root/components/checkout/product_pages/subcomponents/onboarding_fees/onboarding_fees';
import { StorageCheckoutStepProps } from '@root/components/checkout/types';
import { useTrack } from '@root/initializers/wt';
import { useFreePickupReturnEligible } from '@utils/hooks/use_free_pickup_return_eligible';

import { ChallengerBundleCards } from './bundles/challenger_bundle_cards';
import { ControlBundleCards } from './bundles/control_bundle_cards';

export const Bundles: React.FC<StorageCheckoutStepProps> = (props) => {
  const { storageBundleEligible, subjobPackageEligible } =
    useStorageCheckoutContext();
  const {
    onChange,
    scrollToStep,
    enabled,
    values: { bundleKind, commitment, leadToken },
  } = props;
  const track = useTrack();

  useEffect(() => {
    if (commitment === RateGroupKindEnum.Mover)
      onChange('bundleKind', undefined);
  }, [commitment]);

  const freePickupReturnEligible = useFreePickupReturnEligible();

  if (!storageBundleEligible) {
    return (
      <Box margin={['64px 0 0', '72px 0 0']}>
        <OnboardingFees showPrice={enabled} />
      </Box>
    );
  }

  const handleChange = (value: DoorToDoor__BundleEnum) => {
    onChange('bundleKind', value);
    track({ value, label: 'Pickup package', objectName: 'package_radio_card' });
    scrollToStep('next');
  };

  return (
    <>
      {subjobPackageEligible && freePickupReturnEligible ? (
        <ChallengerBundleCards
          selectedBundleKind={bundleKind}
          onBundleKindSelect={handleChange}
        />
      ) : (
        <ControlBundleCards
          selectedBundleKind={bundleKind}
          showPrice={!!leadToken}
          onBundleKindSelect={handleChange}
        />
      )}
    </>
  );
};
