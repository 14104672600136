import React from 'react';

import { Bundles } from '@root/components/checkout/product_pages/steps/smart_storage/bundles';

import { StorageCheckoutStep, StorageStepDescriptor } from '../../../types';
import { Disposal } from '../shared/disposal';
import { AddressAndLeadInfo } from '../smart_storage/address_and_lead_info';
import { Appointment } from '../smart_storage/appointment';
import { Commitment } from '../smart_storage/commitment';
import { PlanSize } from '../smart_storage/plan_size';
import { ProtectionPlan } from '../smart_storage/protection_plan';
import { Zip } from '../smart_storage/zip';

export const SmartStorage: StorageStepDescriptor[] = [
  {
    name: StorageCheckoutStep.Zip,
    step: Zip,
    resolveCompleted: ({ values: { zipValidated } }) => !!zipValidated,
  },
  {
    name: StorageCheckoutStep.PlanSize,
    step: (props) => <PlanSize {...props} movingCheckoutFlow={false} />,
    resolveCompleted: () => true,
  },
  {
    name: StorageCheckoutStep.Commitment,
    step: (props) => <Commitment {...props} movingCheckoutFlow={false} />,
    resolveCompleted: ({ values: { commitment, planSize } }) =>
      // this method accounts for both plan size and commitment as both steps
      // should be interactive initially
      commitment !== undefined && planSize !== undefined,
  },
  {
    name: StorageCheckoutStep.AddressAndLeadInfo,
    step: AddressAndLeadInfo,
    resolveCompleted: ({ values: { address, name, email }, leadToken }) =>
      !!(address?.zip && address.state && leadToken && name && email),
  },
  {
    name: StorageCheckoutStep.Appointment,
    step: Appointment,
    resolveCompleted: ({ values: { dateScheduled } }) => !!dateScheduled,
  },
  {
    name: StorageCheckoutStep.Bundle,
    step: Bundles,
    resolveCompleted: ({ storageBundleEligible, values }) =>
      storageBundleEligible ? !!values.bundleKind : true,
  },
  {
    name: StorageCheckoutStep.Disposal,
    step: Disposal,
    resolveCompleted: ({ disposalEligible, values: { disposalSelected } }) =>
      disposalEligible ? disposalSelected !== undefined : true,
  },
  {
    name: StorageCheckoutStep.ProtectionPlan,
    step: (props) => <ProtectionPlan {...props} movingCheckoutFlow={false} />,
    resolveCompleted: ({ values: { protectionPlan } }) =>
      protectionPlan !== undefined,
  },
  // The actual cart is rendered on a separate page, but stubbed here to support step
  // transitions.
  {
    name: StorageCheckoutStep.Cart,
    step: () => null,
    resolveCompleted: () => true,
  },
];
