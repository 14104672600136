import React, { useState } from 'react';

import styled from '@emotion/styled';
import { DateTime, Duration } from 'luxon';

import { Box, COLORS, FontWeight, SpinLoader, Text } from '@clutter/clean';
import {
  CallbackDepartment,
  CallbackWindow,
  useCallbackWindowsQuery,
} from '@graphql/platform';
import { RadioCard } from '@root/components/checkout/product_pages/subcomponents/radio_card';
import { useTrack } from '@root/initializers/wt';

const TIME_FORMAT = 'h:mma';

const TextBody = styled(Text.Callout)`
  font-weight: 600;
  text-transform: uppercase;
`;

export const formatCallbackWindow = ({ time, duration }: CallbackWindow) => {
  const from = DateTime.fromISO(time);
  const till = from.plus(Duration.fromISO(duration));
  return `${from.toFormat(TIME_FORMAT)} - ${till.toFormat(TIME_FORMAT)}`;
};

export const CallbackWindows: React.FC<{
  department?: CallbackDepartment;
  selectedWindow?: CallbackWindow;
  textSize?: 'small' | 'medium';
  scrollable?: boolean;
  onSelect(callbackWindow: CallbackWindow): void;
}> = ({
  department,
  selectedWindow,
  textSize = 'small',
  scrollable = true,
  onSelect,
}) => {
  const [now] = useState<string>(DateTime.local().toISO());
  const track = useTrack();
  const { data, loading } = useCallbackWindowsQuery({
    fetchPolicy: 'network-only',
    variables: { department },
  });

  if (!data || loading) {
    return <SpinLoader />;
  }
  const { callbackWindows, allowImmediateCallback } = data;

  const businessDay = DateTime.fromISO(callbackWindows?.[0]?.time);
  const sameDayCallbacks =
    businessDay.startOf('day').toMillis() ===
    DateTime.fromISO(now).startOf('day').toMillis();

  const TextComponent = textSize === 'small' ? Text.SmallCaps : TextBody;

  const onTimeSelect = (callbackWindow: CallbackWindow) => {
    const { time, duration } = callbackWindow;
    track({
      action: 'click',
      objectType: 'button',
      objectName: 'callback_window',
      value: time,
      metadata: {
        duration,
        callback_type: time === now ? 'immediate' : 'scheduled',
      },
    });
    onSelect(callbackWindow);
  };

  return (
    <Box
      overflow="auto"
      maxHeight={scrollable ? ['calc(100dvh - 424px)', '300px'] : undefined}
      padding="0 0 12px"
      textAlign="center"
    >
      <Box.Grid gridGap="8px" gridTemplateColumns="1fr 1fr" padding="3px">
        {allowImmediateCallback && (
          <Box.GridItem gridColumn="1 / span 2">
            <RadioCard
              selected={selectedWindow?.time === now}
              onChange={() =>
                onTimeSelect({
                  time: now,
                  duration: Duration.fromObject({ minutes: 10 }).toISO(),
                })
              }
              value="now"
              name="callbackWindow"
            >
              <TextComponent color={COLORS.tealPrimary}>
                Call me now
              </TextComponent>
            </RadioCard>
          </Box.GridItem>
        )}
        {!sameDayCallbacks && (
          <Box.GridItem margin="12px 0" gridColumn="1 / span 2">
            <Text.Callout weight={FontWeight.Medium} color={COLORS.storm}>
              Schedule a call for{' '}
              {businessDay.toLocaleString(DateTime.DATE_MED)}
            </Text.Callout>
          </Box.GridItem>
        )}
        {callbackWindows.map((callbackWindow, key) => (
          <Box.GridItem key={key}>
            <RadioCard
              selected={
                DateTime.fromISO(selectedWindow?.time || '').toMillis() ===
                DateTime.fromISO(callbackWindow.time).toMillis()
              }
              onChange={() => onTimeSelect(callbackWindow)}
              value={callbackWindow.time}
              name="callbackWindow"
            >
              <TextComponent color={COLORS.tealPrimary}>
                {formatCallbackWindow(callbackWindow)}
              </TextComponent>
            </RadioCard>
          </Box.GridItem>
        ))}
      </Box.Grid>
    </Box>
  );
};
